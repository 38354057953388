<template>
  <!-- 分销管理 - 分销商城 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">分销管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">分销商列表</a>
        </span>
      </div>
      <div class="framePage-body">
          <el-tabs v-model="activeName" :before-leave="beforeTabLeave" @tab-click="tabsChange">
            <el-tab-pane label="分销商品" name="first">
              <mallDistribution1 :name="activeName" ref="first" />
            </el-tab-pane>
            <el-tab-pane label="全部商品" name="second">
              <mallDistribution2 :name="activeName" ref="second" />
            </el-tab-pane>
          </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import mallDistribution1 from "@/views/miniApp/retailManagement/mallDistribution_1.vue";
import mallDistribution2 from "@/views/miniApp/retailManagement/mallDistribution_2.vue";
export default {
  name: "mallDistribution",
  components: {
    mallDistribution1,
    mallDistribution2
  },
  data() {
    return {
      activeName: "first"
    };
  },
  computed: {},
  methods: {
    tabsChange(e) {
      this.activeName = e.name;
    }
  },
  created(){

  }
};
</script>
<style lang="less"></style>
