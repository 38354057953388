<template>
  <!-- 分销管理 - 分销商城 - 分销商品 -->
  <div>
    <div class="operationControl">
      <div class="searchbox">
        <div title="商品名称" class="searchboxItem">
          <span class="itemLabel">商品名称:</span>
          <el-input
            v-model="retrievalData.tradeName"
            type="text"
            size="small"
            placeholder="请输入商品名称"
            clearable
          />
        </div>
        <div title="商品分类" class="searchboxItem">
          <span class="itemLabel">商品分类:</span>
          <el-cascader
            :options="tradeTypeData"
            v-model="retrievalData.tradeType"
            :props="miniProps"
            size="small"
            clearable
          ></el-cascader>
        </div>
        <div class="df">
          <el-button type="primary" class="bgc-bv" round @click="getData()"
            >查询</el-button
          >
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
          @sort-change="changeSort"
          :default-sort="{ prop: 'auditTime', order: 'ascending' }"
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
            width="100px"
          />
          <el-table-column
            label="商品名称"
            align="left"
            show-overflow-tooltip
            prop="courseName"
            min-width="200"
          />
          <el-table-column
            label="商品分类"
            align="left"
            show-overflow-tooltip
            prop="courseTypeName"
            min-width="100"
          />
          <el-table-column
            label="市场价"
            align="left"
            :show-overflow-tooltip="true"
            min-width="120"
          >
            <template slot-scope="scope">{{
              scope.row.marketPrice || "--"
            }}</template>
          </el-table-column>
          <el-table-column
            label="分销价"
            align="left"
            :show-overflow-tooltip="true"
            min-width="120"
          >
            <template slot-scope="scope">{{
              scope.row.distributionPrice || "--"
            }}</template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            show-overflow-tooltip
            min-width="100"
          >
            <template slot-scope="scope">
              <el-button
                style="padding: 0 5px"
                type="text"
                size="mini"
                @click="doSee(scope.row)"
                >预览</el-button
              >
              <el-button
                style="padding: 0 5px"
                type="text"
                size="mini"
                @click="doReview(scope.row)"
                >详情</el-button
              >
              <el-button
                style="padding: 0 5px"
                type="text"
                size="mini"
                @click="remove(scope.row)"
                >移除</el-button
              >
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
        <el-dialog
      title="提示"
      :visible.sync="dialogVisible1"
      :before-close="handleClose"
      width="45%"
    >
      <el-form
        :model="tradeSet"
        :rules="rules"
        ref="tradeSet"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="分销价格(元)："
          prop="distributionPrice"
          style="width: 300px"
        >
          <el-input
            v-model="tradeSet.distributionPrice"
            @keyup.native="
              tradeSet.distributionPrice = zF.oninput2(
                tradeSet.distributionPrice,
                2
              )
            "
            size="small"
            placeholder="请输入分销价格"
            style="margin-top: 4px"
          ></el-input>
        </el-form-item>
        <el-form-item label="阶梯优惠：">
          <el-table
            ref="multipleTable"
            :data="tradeSet.stairList"
            height="300"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader"
            stripe
            @sort-change="changeSort"
            :default-sort="{ prop: 'auditTime', order: 'ascending' }"
          >
            <el-table-column
              label="购买数量"
              align="left"
              :show-overflow-tooltip="true"
              min-width="160"
              ><template slot-scope="scope">
                <el-input
                  v-model="scope.row.stairLevel"
                  @keyup.native="
                    scope.row.stairLevel = zF.positiveInteger(
                      scope.row.stairLevel
                    )
                  "
                  size="small"
                  placeholder="请输入购买数量"
                  style="width: 60%; margin-right: 6px"
                ></el-input
                >及以上
              </template>
            </el-table-column>
            <el-table-column
              label="折扣"
              align="left"
              :show-overflow-tooltip="true"
              min-width="160"
            >
              <template slot-scope="scope"
                ><el-input
                  v-model="scope.row.stairDiscount"
                  @keyup.native="
                    scope.row.stairDiscount = zF.oninput2(
                      scope.row.stairDiscount,
                      2
                    )
                  "
                  size="small"
                  placeholder="请输入折扣"
                  style="width: 60%; margin-right: 6px"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
              show-overflow-tooltip
              min-width="100"
            >
              <template slot-scope="scope">
                <el-button
                  style="padding: 0 5px"
                  type="text"
                  size="mini"
                  @click="delStairList(scope.$index)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </el-form-item>
        <el-form-item label="">
          <el-button
            type="primary"
            class="bgc-bv"
            round
            size="small"
            @click="addStairList"
            >添加价梯优惠</el-button
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="bgc-bv"
          size="small"
          round
          style="padding: 8px 30px"
          @click="tradeSetqx"
          >取 消</el-button
        >
        <el-button
          type="primary"
          class="bgc-bv"
          round
          size="small"
          style="padding: 8px 30px"
          @click="tradeSetqd"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "CoursewareToBereviewed",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  props:['name'],
  data() {
    return {
      // 检索数据
      retrievalData: {
        tradeName: "", // 商品名称
        tradeType: "", // 商品分类
      },
      // 商品分类下拉数据
      tradeTypeData: [],
      // 商品分类下拉数据 - 对应数据类型
      miniProps: {
        label: "name",
        value: "courseTypeId",
        checkStrictly: false,
        emitPath: false,
      },
      // 商品设置
      tradeSet: {
        distributionPrice: "", // 分销价格
        stairList: [
          {
            stairLevel: "", // 购买数量
            stairDiscount: "", // 折扣
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    // 获取课程分类数组数据
    getcourseTypeArrData() {
      this.$post(
        "miniapp/coursetype/selectTree",
        { hiddenState: "10" },
        3000,
        true,
        2
      ).then((ret) => {
        this.tradeTypeData = this.delchild(ret.data);
      });
    },
    // 处理-获取课程分类数组数据
    delchild(list = []) {
      for (let i = 0; i < list.length; i++) {
        const el = list[i];
        if (el.children.length == 0) {
          delete el.children;
        }
        this.delchild(el.children);
      }
      return list;
    },
    // 获取列表数据
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        courseName: this.retrievalData.tradeName,
        courseTypeId: this.retrievalData.tradeType,
        type: 20,
      };
      this.doFetch(
        {
          url: "/course/distribution/selectAll",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    // 预览
    doSee(row) {
      this.$router.push({
        path: "/web/miniApp/previewCourse",
        query: {
          courseId: row.courseId,
          yj: row.marketPrice,
          courseType: row.courseTypeName,
        },
      });
    },
    // 详情
    doReview(row) {
      this.courseId = row.courseId;
      if (row.courseDistribution) {
        this.courseDistribution = row.courseDistribution;
        this.$post(
          "/course/distribution/getInfo",
          { courseDistribution: row.courseDistribution },
          3000,
          true,
          2
        ).then((res) => {
          this.tradeSet.distributionPrice = res.data.distributionPrice;
          this.tradeSet.stairList = res.data.stairList;
        });
      }
      this.dialogVisible1 = true;
    },
    // 设置分销商品 - 取消
    handleClose(done) {
      // 主键-新增不用传，修改需要传
      this.courseDistribution = "";
      // 精品课程ID
      this.courseId = "";
      // 商品设置
      this.tradeSet = {
        distributionPrice: "", // 分销价格
        stairList: [
          {
            stairLevel: "", // 购买数量
            stairDiscount: "", // 折扣
          },
        ],
      };
      done();
    },
    // 设置分销商品 - 删除价梯优惠
    delStairList(ix) {
      this.tradeSet.stairList.splice(ix, 1);
    },
    // 设置分销商品 - 添加价梯优惠
    addStairList() {
      this.tradeSet.stairList.push({
        stairLevel: "", // 购买数量
        stairDiscount: "", // 折扣
      });
    },
    // 设置分销商品 - 取消
    tradeSetqx() {
      // 主键-新增不用传，修改需要传
      this.courseDistribution = "";
      // 精品课程ID
      this.courseId = "";
      // 商品设置
      this.tradeSet = {
        distributionPrice: "", // 分销价格
        stairList: [
          {
            stairLevel: "", // 购买数量
            stairDiscount: "", // 折扣
          },
        ],
      };
      this.dialogVisible1 = false;
    },
    // 设置分销商品 - 确定
    tradeSetqd() {
      this.$refs["tradeSet"].validate((valid) => {
        if (valid) {
          let url = "";
          let params = {
            courseId: this.courseId,
            distributionPrice: this.tradeSet.distributionPrice,
            stairList: this.tradeSet.stairList,
          };
          let arr = [];
          if(this.tradeSet.stairList.length>0){
            for (let i = 0; i < this.tradeSet.stairList.length; i++) {
              arr.push(this.tradeSet.stairList[i].stairLevel);
              if (
                !this.tradeSet.stairList[i].stairLevel ||
                !this.tradeSet.stairList[i].stairDiscount
              ) {
                this.$message({
                  message: "请输入完整的阶梯优惠!",
                  type: "warning",
                });
                return;
              } else if(this.tradeSet.stairList[i].stairDiscount == 0){
                this.$message({
                  message: "折扣价不能为0!",
                  type: "warning",
                });
                return;
              }
            }
            if(this.zF.isRepeat(arr)){
              this.$message({
                  message: "购买数量不能重复!",
                  type: "warning",
                });
                return;
            }
          }       
          if (this.courseDistribution) {
            url = "/course/distribution/update";
            params.courseDistribution = this.courseDistribution;
          } else {
            url = "/course/distribution/insert";
          }
          this.$post(url, params, 3000, true, 2).then((res) => {
            if (res.status == 0) {
              // 主键-新增不用传，修改需要传
              this.courseDistribution = "";
              // 精品课程ID
              this.courseId = "";
              // 商品设置
              this.tradeSet = {
                distributionPrice: "", // 分销价格
                stairList: [
                  {
                    stairLevel: "", // 购买数量
                    stairDiscount: "", // 折扣
                  },
                ],
              };
              this.getData();
              this.dialogVisible1 = false;
              this.$message({
                message: "设置成功!",
                type: "success",
              });
            }
          });
        }
      });
    },
    // 移除
    remove(row) {
      this.$confirm("您确定要移除该商品么, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          this.$post(
            "/course/distribution/delete",
            { courseDistribution: row.courseDistribution },
            3000,
            true,
            2
          ).then((res) => {
            this.getData();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  watch: {
    name: {
      handler: function(val, oldVal) {
        if (val == 'first') {       
          this.getData();
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  created() {
    this.getcourseTypeArrData();
  },
};
</script>
<style lang="less">
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
</style>
