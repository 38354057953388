import { render, staticRenderFns } from "./mallDistribution_1.vue?vue&type=template&id=fb8ff51c"
import script from "./mallDistribution_1.vue?vue&type=script&lang=js"
export * from "./mallDistribution_1.vue?vue&type=script&lang=js"
import style0 from "./mallDistribution_1.vue?vue&type=style&index=0&id=fb8ff51c&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports